import React from 'react'
import Navigation from '../functionality/navigation/navigation'
import Footer from '../page/footer'
import MetaTags from './metaTags'
import { ErrorBoundary } from '../functionality/errorReporting'
import ErrorScreen from '../functionality/errorScreen'

export default function Layout({
  children,
  meta,
  fadeNav = false,
  showNavEventsButton,
  showPenguinLogo,
}) {
  return (
    <>
      <MetaTags {...meta} />

      <Navigation
        backgroundFade={fadeNav}
        showEventsButton={showNavEventsButton}
      />
      <ErrorBoundary FallbackComponent={ErrorScreen}>{children}</ErrorBoundary>
      <Footer showPenguinLogo={showPenguinLogo} />
    </>
  )
}
